@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../font/Poppins-Light.f41b63c0bb0963ace821.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Poppins-Regular.8081832fc5cfbf634aa6.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url(../font/Poppins-Italic.ce3fff31c0f262aca227.ttf) format('truetype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../font/Poppins-Medium.9e1bb626874ed49aa343.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../font/Poppins-SemiBold.cce5625b56ec678e4202.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../font/Poppins-Bold.404e299be26d78e66794.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../font/Poppins-ExtraBold.4ed78cd5d64af4efc3c7.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../font/Poppins-Black.eacee5bb9c7fee5939ae.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(../font/Raleway-Light.f25e9b3a1fc8e48e53f5.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Raleway-Regular.9ade142e81dd8d55cd32.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url(../font/Raleway-Italic.2a3303d3281dbe80bec6.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(../font/Raleway-Medium.1af9f1e9f3fd4766df83.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(../font/Raleway-SemiBold.243af299ad3631bfc369.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(../font/Raleway-Bold.d1a96e2aa6257c864d4d.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(../font/Raleway-ExtraBold.ac7bcb8a0f4abc1d6591.ttf) format('truetype');
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(../font/Raleway-Black.eef1ff812c4eced3a276.ttf) format('truetype');
}
html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}

body p {
    font-weight: 500;
    font-size: 17px;
    line-height: 30px;
    color: #504356;
}

h1, h2, h3, .home-table thead, .home-table tbody .t-site-link {
    font-family: 'Poppins', sans-serif;
}

body, .home-table tbody {
    font-family: 'Raleway', sans-serif;
}

h1 {
    font-weight: 600;
    font-size: 52px;
    color: #4A246F;
    margin-top: 33px;
    margin-bottom: 34px;
}

h2 {
    color: #4A246E;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    margin-bottom: 36px;
}

h2 b {
    font-weight: 600;
}

.d-none {
    display: none;
}

.text-center {
    text-align: center;
}

.m-center {
    margin: 0 auto;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 24px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
    text-decoration: none;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-header {
    width: 100%;
    background: #f3ebe7;
    padding-bottom: 96px;
    position: relative;
    overflow: hidden;
}
 
.site-header .content-wrapper {
    width: 788px;
}

.site-header .container {
    position: relative;
    z-index: 10;
}

.header-image {
    width: 814px;
    height: 581px;
    background-image: url(../image/header.webp);
    background-repeat: no-repeat;
    position: absolute;
    top: 44px;
    right: -342px;
}

.header-circle {
    position: absolute;
    background: #EDE5F4;
    filter: blur(222px);
}

.circle-1 {
    width: 356px;
    height: 356px;
    left: -235px;
    top: -199px;
    z-index: -1;
}

.circle-2 {
    position: absolute;
    width: 684px;
    height: 684px;
    left: 282px;
    top: 367px;
    z-index: -1;
}

.circle-3 {
    position: absolute;
    width: 640px;
    height: 640px;
    left: 100%;
    top: -299px;
    z-index: -1;
}

.header-description {
    line-height: 30px;
    padding-bottom: 30px;
}

.header-texts {
    margin-bottom: 72px;
}

.read-more-wrapper {
    margin-top: 12px;
}

.read-more {
    color: blue;
    text-decoration: underline;
}

.read-more:hover {
    cursor: pointer;
}

.logo-wrapper {
    margin-bottom: 14px;
}

.site-logo {
    display: block;
    width: 167px;
    height: 27px;
    background-image: url(../image/logo.svg);
    background-repeat: no-repeat;
    text-indent: -9999px;
}

footer .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #828C98;
}

.gradient-btn .inner {
    position: relative;
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 69px;
    transition: font-size 0.4s linear;
    background: linear-gradient(94.06deg, #870FFE 1.25%, #B374D3 71.25%);
}

.gradient-btn .inner:after {
    content: '';
    opacity: 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    transition: opacity .4s linear;
    background: linear-gradient(94.06deg, #B374D3 1.25%, #870FFE 71.25%);
}

.gradient-btn:hover .inner:after {
    opacity: 1; 
}

.gradient-btn  {
    padding: 3px;
    border-radius: 69px;
    background: rgb(231,183,255);
    background: linear-gradient(280deg, rgba(231,183,255,1) 36%, rgba(225,197,239,1) 56%);
    box-shadow: 0px 35px 73px -11px rgba(102, 84, 131, 0.5);
    width: 354px;
    height: 82px;
}

.gradient-btn .btn {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    border: 0;
    background-color: transparent;
    cursor: pointer;
}

.slide-down {
    height: 0;
    overflow: hidden;
    transition: height 0.2s linear;
}

.main {
    padding-top: 75px;
    position: relative;
    z-index: 10;
    background: #fff;
}

section:nth-of-type(1) p {
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
}

#best {
    position: relative;
}

#best::before {
    content: ' ';
    width: 110px;
    height: 154px;
    background-image: url(../image/arrows-1.png);
    display: block;
    position: absolute;
    left: 42px;
    top: 0;
}

#best::after {
    content: ' ';
    width: 203px;
    height: 283px;
    background-image: url(../image/arrows-2.png);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
}

/* Home table styles ↓↓↓ */

.t-section {
    padding: 15px;
    margin: 30px 0;
}

.t-tabs ul {
    list-style: none;
    margin-bottom: 60px;
    text-align: center;
}

.t-tabs ul li {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 19px rgba(46,32,86,.1);
    display: inline-block;
    margin: 0 20px;
    position: relative;
    transition: margin .2s linear;
    cursor: pointer;
}

.t-tabs ul li a {
    display: inline-block;
    width: 240px;
    height: 72px;
    line-height: 80px;
    text-decoration: none;
    text-align: center;
    margin-bottom: 3px;
    margin-left: 10px;
    margin-right: 10px;
}

.t-tabs ul li a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: normal;
    color: #2E2056;
    border-bottom: 4px solid #AA5DDF;
    transition: border 0.2s linear;
}

.t-tabs ul li.active a, 
.t-tabs ul li:hover a {
    border-bottom: 4px solid #FFC737;
}

.t-tabs ul li.active a::after {
    border-top: 12px solid #FFC737;
    top: 93%;
    z-index: 2;
}

.t-tabs ul li.active::after {
    border-top: 12px solid #fff;
    top: 100%;
    z-index: 1;
}

.t-tabs ul li.active::after, 
.t-tabs ul li.active a::after {
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    clear: both;
    content: '';
    height: 0;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
}

.t-section .tab {
    height: 0;
    opacity: 0;
    overflow: hidden;

}

.t-section .tab.active {
    height: auto;
    opacity: 1;
    transition: opacity 0.3s linear;
}

.home-table {
    width: 100%;
    border: 0;
    border-collapse: separate;
    border-spacing: 0 10px;
    font-size: 14px;
    color: #2E2056;
    background-color:#4A246E;
    border-radius: 26px;
    padding: 17px;
}

.home-table thead {
    color: #96A0AE;
    font-style: normal;
    font-weight: normal;
    text-transform: uppercase;
}

.home-table td {
    border: solid 1px #000;
    border-style: solid none;
    padding: 6px;
    background-color: #F9FBFF;
    position: relative;
}

.home-table td:first-child {
    border-left-style: solid;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
}

.home-table td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 28px;
    border-top-right-radius: 28px;
}

.home-table td:nth-of-type(2) {
    font-weight: bold;
}

.home-table td:nth-of-type(3) ul li:not(:last-child) {
    margin-bottom: 15px;
}

.home-table td:nth-of-type(4) {
    font-family: Poppins;
    font-weight: 600;
    font-size: 18px;
}

.home-table td .rating-img {
    width: 23px;
    height: 23px;
    background-image: url(../image/star.png);
    float:left;
    margin-right: 14px;
}

.home-table td:nth-of-type(2)::after,
.home-table td:nth-of-type(3)::after {
    background-color: #f1f4f9;
    content: '';
    height: 80%;
    position: absolute;
    right: 0;
    top: 10px;
    width: 1px;
}

.home-table td:nth-of-type(3) ul li::before {
    color: #72dbf6;
    content: "\2022";
    display: inline-block;
    font-weight: 700;
    margin-left: -1em;
    width: 1em;
}

.home-table td:nth-of-type(3) ul {
    padding-left: 10px;
}

.home-table li {
    margin-left: 20px;
}

.home-table .t-logo:hover,
.home-table .t-site-link:hover {
    cursor: pointer;
}

.home-table .t-logo {
    background-color: #F1F4F9;
}

.home-table .t-site-link {
    width: 181px;
    height: 56px;
    line-height: 56px;
    background: linear-gradient(94.06deg, #8A16FC 1.25%, #B475D4 71.25%);
    box-shadow: 0px -28px 73px -11px rgba(133, 126, 146, 0.5);
    border-radius: 69px !important;
    text-decoration: none;
    margin: 0 auto;
}

.home-table .t-logo {
    height: 91px;
    line-height: 91px;
    width: 210px;
    text-indent: -5000px;
    background-repeat: no-repeat;
    background-position: center;
}

.home-table .t-logo, 
.home-table .t-site-link {
    display: block;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    border-radius: 25px;
    color: #FFFFFF;
}

.home-table tr:hover .t-site-link {
    background: #FFC737;
}

.home-table tr:hover td {
    border-top: solid 1px #FFC737;
    border-bottom: solid 1px #FFC737;
}

.home-table tr:hover td:first-of-type {
    border-left: solid 1px #FFC737;
}

.home-table tr:hover td:last-of-type {
    border-right: solid 1px #FFC737;
}

#nft-slot-casino tr:nth-of-type(1) .t-logo {
    background-image: url(../image/decentraland-logo.png);
}

#nft-slot-casino tr:nth-of-type(2) .t-logo {
    background-image: url(../image/rollbit-logo.png);
}

#nft-slot tr:nth-of-type(1) .t-logo {
    background-image: url(../image/nftmegaways-logo.png);
}

#nft-slot tr:nth-of-type(2) .t-logo {
    background-image: url(../image/celebrity-slots-logo.png);
}

#nft-slot-event tr:nth-of-type(1) .t-logo {
    background-image: url(../image/decentraland-logo.png);
}

#nft-slot-event tr:nth-of-type(2) .t-logo {
    background-image: url(../image/betfury-logo.png);
}

.what-is {
    background: #f5f4f7;
    border-radius: 30px;
    margin: 70px 0 60px;
}

.what-is .inner-img {
    display: flex;
}

.what-is .inner {
    padding: 40px 40px 20px 10px;
    text-align: left;
}

.what-is h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 24px;
}

.h-with-img {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.h-with-img:before {
    content: '';
    display: block;
    width: 90px;
    height: 51px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-right: 16px;
}

section:nth-of-type(3) h2::before {
    background-image: url(../image/slot-machine.png);
}

section:nth-of-type(4) h2::before {
    background-image: url(../image/seven.png);
}

section:nth-of-type(5) h2::before {
    background-image: url(../image/diamond.png);
}

section:nth-of-type(6) h2::before {
    background-image: url(../image/cherries.png);
}

section:nth-of-type(7) h2::before {
    background-image: url(../image/bonus.png);
}

section ul {
    list-style: none;
}

.custom-ul li:before {
    content: '\2713';
    margin-right: 16px;
    color: #A3EF7C;
}

.custom-ul li:not(:last-child) {
    margin-bottom: 20px;
}

.block {
    margin-top: 82px;
    margin-bottom: 38px;
    padding-top: 47px;
    padding-left: 52px;
    padding-right: 52px;
    padding-bottom: 70px;
    background-color: #FFFFFF;
    border-radius: 20px;
    box-shadow: 11px 14px 60px rgba(46, 92, 118, 0.2);
}

.block .title {
    color: #261747;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 58px;
}

.block .content p {
    font-size: 17px;
    line-height: 30px;
    color: #63587B;
}

.block .content p:not(:last-child) {
    margin-bottom: 35px;
}

.block .content .step-title {
    font-weight: 700;
    margin-bottom: 8px !important;
}

.container.bottom .block {
    background: linear-gradient(94.3deg,#46b588 -1.78%,#02c2ff -1.77%,#009fd1 84.81%);
  
}

.container.bottom .title,
.container.bottom .content p,
.container.bottom .content a {
    color: #fff !important;
}

.demo {
    width: 100%;
    height: 500px;
    background-image: url(../image/nft-megaways-paytable.webp);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    margin-bottom: 24px;
}

.demo .gradient-btn {
    z-index: 100;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

/* FAQ styles ↓↓↓ */

.faq-section-wrapper {
    padding-top: 42px;
}

.faq-heading {
    margin-bottom: 38px !important;
    padding-top: 68px !important;
}

.faq:not(:first-of-type) {
    border-top: 1px solid #E5EFF9;
}

.faq.expanded+.faq {
    border-top: 0px;
}

.faq-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-title h3 {
    color: #261747;
    font-weight: normal;
    font-size: 28px;
    height: 98px;
    line-height: 98px;
    pointer-events: none;
}

.faq-btn {
    width: 49px;
    height: 49px;
    border: 0;
    border-radius: 25px;
    background: rgb(246,246,246);
    background: linear-gradient(342deg, rgba(246,246,246,1) 42%, rgba(233,241,249,1) 69%);
    margin-right: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
}

.faq.expanded .faq-btn {
    background: linear-gradient(342deg, rgba(233,241,249,1) 42%, rgba(246,246,246,1) 69%);
}

.faq-btn:hover, .faq-title:hover {
    cursor: pointer;
}

.faq-btn:hover {
    background-color: #e9eef5;
    border-radius: 25px;
    transition: background-color 0.2s ease;
}

.faq {
    padding-left: 56px;
    transition: background-color 0.2s linear, font-weight 0.2s linear, border-radius 0.2s linear;
}

.faq-section-wrapper p {
    font-size: 17px;
}

.faq .faq-body {
    font-size: 17px;
    line-height: 24px;
    color: #404447;
    padding-right: 142px;
    padding-bottom: 44px;
}

.faq.collapsed .faq-body {
    display: none;
}

.faq.expanded {
    background-color: rgba(229, 239, 249, 0.8);
    border-radius: 15px;
}

.faq-btn span {
    pointer-events: none;
}

.faq.collapsed .faq-btn span {
    color: #02c2ff;
 }

.faq.expanded .faq-btn span {
    color: black;
}

.faq.expanded .faq-btn span.minus,
.faq.collapsed .faq-btn span.plus {
    display: block;
}

.faq.collapsed .faq-btn span.minus,
.faq.expanded .faq-btn span.plus {
    display: none;
}

.faq.expanded .faq-title h3 {
    font-weight: 700;
}

.container.bottom {
    color: white;
}

.container.bottom ul {
    list-style: none;
    margin-left: 36px;
}

.container.bottom ul li:before {
    content: "\2022";  
    color: #6165D9; 
    font-weight: bold; 
    font-size: 12px;
    display: inline-block; 
    width: 3em; 
    margin-left: -3em; 
}

.container.bottom ul li {
    margin-bottom: 28px;
    font-size: 18px;
    line-height: 24px;
}

.m-visible {
    display: none;
}

.m-hidden {
    display: initial;
}

@media only screen and (max-width: 768px) {

    h1 {
        font-size: 38px;
    }

    h2 {
        font-size: 26px;
    }
    
    header .subtitle {
        font-size: 18px;
    }

    .mobile-detector {
        display: none;
    }

    .m-visible {
        display: initial;
    }
    
    .m-hidden {
        display: none;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }

    .col1-2, .col2, .col2-1 {
        grid-template-columns: 1fr;
    }

    .site-header {
        background-image: url(../image/arrows-2.png);
        background-position: 96% 10px;
        background-repeat: no-repeat;
        padding-left: 22px;
        padding-right: 22px;
        padding-bottom: 42px;
    }
    .header-image {
        display:none;
    }

    .site-header .content-wrapper {
        width: 100%;
        position: relative;
        z-index: 100;
    }

    .header-texts {
        margin-bottom: 42px;
    }

    .main {
        padding-top: 42px;
    }

    #best::before, 
    #best::after {
        background-image: none;
    }

    section:nth-of-type(1) {
        padding:0 22px;
    }

    .t-section {
        margin-top: 16px;
        margin-bottom: 0px;
        padding: 0;
    }

    .t-tabs {
        display: block;
        overflow: hidden;
    }

    .t-tabs ul {
        min-width: auto;
        width: 1200px;
        margin-bottom: 28px;
        text-align: initial;
    }

    .home-table tr {
        display: table;
        background: #f9fbff;
        border-radius: 50px;
        max-width: -webkit-fill-available;
        width: 100%;
        padding: 10px 16px;
        margin-bottom: 16px;
    }

    .home-table thead tr {
        display: none;
    }

    .home-table td {
        display: inline-table;
        border: none !important;
        padding: 0;
        position: relative;
    }

    .home-table td:nth-of-type(5) {
        width: 100%;
        max-width: 450px;
        border: none;
        display: block;
        margin: 0px auto;
        border-radius: 50px;
        padding-top: 6px;
    }

    .home-table td:nth-of-type(1),
    .home-table td:nth-of-type(2),
    .home-table td:nth-of-type(3),
    .home-table td:nth-of-type(4) {
        width: 48%;
        padding-left: 4%;
    }

    .home-table td:nth-of-type(2)::before,
    .home-table td:nth-of-type(3)::before,
    .home-table td:nth-of-type(4)::before {
        color: #96a0ae;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        left: 18px;
        position: absolute;
        text-transform: uppercase;
        top: -12px;
        width: 45%;
    }

    .home-table li {
        margin: 0;
    }

    .home-table td:nth-of-type(2)::before {
        content: 'Type';
    }

    .home-table td:nth-of-type(3) {
        margin-top: 12px;
    }

    .home-table td:nth-of-type(3)::before {
        content: 'Highlights';
    }

    .home-table td:nth-of-type(4)::before {
        content: 'Rating';
    }

    .home-table .t-logo {
        width: 100%;
        background-size: 90%;
    }

    .home-table .t-logo,
    .home-table .t-site-link {
        height: 64px;
        line-height: 64px;
    }

    .home-table .t-site-link {
        width: 90%;
        margin: 0 auto;
        box-shadow: none;
    }

    .h-with-img::before {
        margin-right: 12px;
    }

    .what-is .inner-img img {
        width: 100%;
    }
    
    .what-is .inner {
        padding-left: 38px;
        padding-right: 38px;
        padding-top: 16px;
        padding-bottom: 42px;
    }

    .what-is {
        margin-left: 22px;
        margin-right: 22px;
        margin-bottom: 40px;
        margin-top: 30px;
    }

    .block {
        margin-left: 22px;
        margin-right: 22px;
        margin-top: 36px;
        margin-bottom: 19px;
        padding-left: 38px;
        padding-right: 38px;
        padding-bottom: 42px;
    }

    .block .header .title {
        height: auto;
    }   

    .block .header .img {
        flex-shrink: 0;
    }

    .block .footer .images {
        padding-left: 24px;
        position: relative;
        height: 215px;
    }

    .block .footer img {
        width: 274px;
        margin-left: 0px !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* Mobile read more slide area ↓↓↓ */

    .m-readmore-btn {
        color: #2E2056;
        height: 60px;
        width: 100%;
        border-radius: 20px;
        border: 1px solid #2E2056;
        background-color: transparent;
        text-align: center;
        font-weight: 900;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 18px;
    }

    .m-readmore-btn:active {
        background-color: #fff0e6;
    }

    .m-readmore-btn .img {
        width: 16px;
        height: 18px;
        margin-left: 18px;
    }

    .m-readmore-btn span {
        pointer-events: none;
    }

    .m-slider-nav {
        width: 120px;
        margin: 8px auto 0px;
        padding-bottom: 20px;
    }

    .m-slider-nav ul {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: nowrap;
    }

    .m-slider-nav ul li {
        list-style-type: none;
        width: 7px;
        height: 7px;
        background-color: #DEDDE2;
        border-radius: 12px;
    }

    .m-slider-nav ul li.active {
        background-color: #FFC737;
    }

    .m-slider-img {
        height: 0;
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    .m-slider-img.active {
        opacity: 1;
        height: auto;
    }

    .faq-wrapper {
        padding-left: 22px;
        padding-right: 22px;
    }

    .faq {
        padding-left: 22px;
    }

    .faq-title {
        padding-bottom: 12px;
        padding-top: 10px;
    }

    .faq-title h3 {
        font-size: 20px;
        line-height: 38px;
        padding-top: 10px;
        padding-right: 10px;
        height: auto;
    }

    .faq .faq-body {
        padding-right: 22px;
    }

    .faq-btn {
        flex-shrink: 0;
        margin-right: 12px;
    }

    footer .container {
        display: block;
        text-align: center;
        padding-bottom: 20px;
    }
}


